import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './LoadingSpinner.scss';

export const spinnerSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGER: 'large'
};

/** The component renders an animated loading indicator */
function LoadingSpinner({
  text = 'Loading...',
  size = spinnerSizes.MEDIUM,
  customClass = '',
  customSpinnerColor = ''
}) {
  return (
    <div className={classnames(styles.loadingSpinner, customClass)}>
      <div className={styles[size]}>
        <SVGIcon fill={customSpinnerColor} glyph={GLYPHS.ICON_LOADING} />
      </div>
      <p>{text}</p>
    </div>
  );
}

LoadingSpinner.propTypes = {
  text: PropTypes.string || PropTypes.object,
  size: PropTypes.oneOf(Object.values(spinnerSizes)),
  customClass: PropTypes.string,
  customSpinnerColor: PropTypes.string
};

export default LoadingSpinner;
