import * as t from '../actionTypes';

const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };

const initialState = {
  placementTests: [],
  refreshData: false,
  editJoiningCode: false,
  codeDetails: {},
  joiningCodeUpdated: false,
  errorResponse: ''
};

function loadPlacementTestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_PLACEMENT_TESTS:
      return {
        ...initialState,
        ...requestStatusOptions
      };
    case t.GET_PLACEMENT_TESTS_SUCCESS:
      return {
        ...state,
        placementTests: payload,
        ...successStatusOptions
      };
    case t.GET_PLACEMENT_TESTS_FAILURE:
      return { ...state, ...failureStatusOptions };
    case t.REFRESH_PLACEMENT_TEST_LIST:
      return {
        ...state,
        refreshData: true,
        ...successStatusOptions
      };
    case t.OPEN_EDIT_JOINING_CODE:
      return { ...state, editJoiningCode: true, ...requestStatusOptions };

    case t.CLOSE_EDIT_JOINING_CODE:
      return { ...state, editJoiningCode: false };

    case t.GET_JOINING_CODE_DETAILS_SUCCESS:
      return {
        ...state,
        codeDetails: payload,
        ...successStatusOptions
      };
    case t.GET_JOINING_CODE_DETAILS_FAILURE:
      return { ...state, ...failureStatusOptions };

    case t.SUBMIT_EDIT_JOINING_CODE: {
      return { ...state, ...requestStatusOptions };
    }

    case t.SUBMIT_EDIT_JOINING_CODE_SUCCESS:
      return { ...state, ...successStatusOptions, joiningCodeUpdated: true };

    case t.SUBMIT_EDIT_JOINING_CODE_FAILURE:
      return { ...state, ...failureStatusOptions, joiningCodeUpdated: false, errorResponse: payload };
    case t.EDIT_ERROR_RESPONSE:
      return { ...state, errorResponse: false };
    case t.EDIT_JOINING_CODE_UPDATED:
      return { ...state, joiningCodeUpdated: false };
    default:
      return state;
  }
}

export default loadPlacementTestsReducer;
