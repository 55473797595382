import * as t from '../actionTypes';

export const loadPlacementTestsRequest = data => ({
  type: t.GET_PLACEMENT_TESTS,
  payload: data
});

export const loadPlacementTestsSuccess = payload => ({
  type: t.GET_PLACEMENT_TESTS_SUCCESS,
  payload
});

export const loadPlacementTestsFailure = payload => ({
  type: t.GET_PLACEMENT_TESTS_FAILURE,
  payload
});

export const refreshPlacementTestList = payload => ({
  type: t.REFRESH_PLACEMENT_TEST_LIST,
  payload
});

export const openEditJoiningCodePanel = () => ({
  type: t.OPEN_EDIT_JOINING_CODE
});
export const closeEditJoiningCodePanel = () => ({
  type: t.CLOSE_EDIT_JOINING_CODE
});

export const loadJoiningCodeDetailsSuccess = payload => ({
  type: t.GET_JOINING_CODE_DETAILS_SUCCESS,
  payload
});

export const loadJoiningCodeDetailsFailure = payload => ({
  type: t.GET_JOINING_CODE_DETAILS_FAILURE,
  payload
});

export const submitEditJoiningCodeRequest = data => ({
  type: t.SUBMIT_EDIT_JOINING_CODE,
  payload: data
});

export const submitEditJoiningCodeSuccess = () => ({
  type: t.SUBMIT_EDIT_JOINING_CODE_SUCCESS
});

export const submitEditJoiningCodeFailure = payload => ({
  type: t.SUBMIT_EDIT_JOINING_CODE_FAILURE,
  payload
});

export const editErrorResponseField = () => ({
  type: t.EDIT_ERROR_RESPONSE
});

export const editJoiningCodeUpdatedField = () => ({
  type: t.EDIT_JOINING_CODE_UPDATED
});
