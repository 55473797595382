import { put, select } from 'redux-saga/effects';
import getDetailsFromJoiningCodeApi from '../classroom/getDetailsFromJoiningCodeApi';
import { loadJoiningCodeDetailsSuccess, loadJoiningCodeDetailsFailure } from '../../../../actions/placementTests';

function* loadJoiningCodeDetails() {
  const { customOrgId, joiningCode } = yield select(state => ({
    customOrgId: state.organisations?.data[state.identity.currentOrganisationId]?.customId,
    joiningCode: state.placementTestSessionCreate.joiningCode
  }));
  const classCode = `${customOrgId}-${joiningCode}`;

  const response = yield getDetailsFromJoiningCodeApi(classCode);
  if (response.status === 'success') {
    yield put(loadJoiningCodeDetailsSuccess(response.data));
  } else {
    yield put(loadJoiningCodeDetailsFailure({}));
  }
}

export default loadJoiningCodeDetails;
